<template>
  <div
    class="booking-proposal d-flex align-center pa-2"
    data-cy="booking-proposal"
  >
    <Thumbnail
      v-if="calendarItem.orderLine.imageUrl"
      :image-url="calendarItem.orderLine.imageUrl"
      size="small"
      cover
      class="mr-2"
    />
    <div>
      <div>
        <span class="text-bold">{{ calendarItem.duration }} min</span>
        {{ calendarItem.orderLine.title }}
      </div>
      <div class="d-flex align-center">
        <HIcon icon-name="UserIcon" size="16" class="mr-1" />
        <span>
          {{ calendarItem.customer.firstName }}
          {{ calendarItem.customer.lastName }}
        </span>
      </div>
      <div class="d-flex align-center">
        <HIcon icon-name="BuildingStoreIcon" size="16" class="mr-1" />
        <span>{{ calendarItem.shop.name }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import Vue, { PropType } from 'vue'

import { HIcon } from '@happytal/bo-ui-library'

import Thumbnail from './Thumbnail.vue'

export default Vue.extend({
  components: {
    HIcon,
    Thumbnail,
  },
  props: {
    calendarItem: {
      type: Object,
      required: true,
    },
  },
})
</script>

<style lang="scss" scoped>
.booking-proposal {
  background-color: var(--v-primary-base);
  border-radius: 4px;
  color: white;
  font-size: 12px;
}
</style>
