<template>
  <div class="d-flex align-center">
    <HIcon icon-name="PhoneIcon" size="16" class="mr-1" />
    <a :href="`tel:${formattedValue}`" target="_blank">
      {{ formattedValue }}
    </a>
  </div>
</template>

<script>
import mixins from 'vue-typed-mixins'

import { HIcon } from '@happytal/bo-ui-library'

import Formatters from '@/mixins/Formatters'

export default mixins(Formatters).extend({
  components: {
    HIcon,
  },
  props: {
    value: {
      type: String,
      required: true,
    },
  },
  computed: {
    formattedValue() {
      return this.formatPhone(this.value)
    },
  },
})
</script>
