<template>
  <ConditionalTooltip tag="span" :enabled="relative" color="neutral">
    <template #activator>
      <span>{{ relative ? relativeValue : formattedValue }}</span>
    </template>
    <template #tooltip>{{ formattedValue }}</template>
  </ConditionalTooltip>
</template>

<script>
import Vue, { PropType } from 'vue'
import { format, formatRelative } from 'date-fns'
import { fr } from 'date-fns/locale'

import ConditionalTooltip from '@/components/ConditionalTooltip.vue'

export default Vue.extend({
  components: {
    ConditionalTooltip,
  },
  props: {
    value: {
      type: Date,
      required: true,
    },
    pattern: {
      type: String,
      default: 'date',
      validator: (val) => ['date', 'date-time', 'time'].includes(val),
    },
    relative: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    formattedValue() {
      let formatPattern = ''
      switch (this.pattern) {
        case 'date':
          formatPattern = 'P'
          break
        case 'date-time':
          formatPattern = 'P p'
          break
        case 'time':
          formatPattern = 'p'
          break
      }
      return format(this.value, formatPattern, {
        locale: fr,
      })
    },
    relativeValue() {
      return formatRelative(this.value, new Date(), {
        locale: fr,
      })
    },
  },
})
</script>
