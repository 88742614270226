<template>
  <span class="d-inline-flex align-center">
    <HTooltip v-if="isHappytal" color="neutral">
      <template #activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on">Happytal</span>
      </template>
      <span>{{ name }}</span>
    </HTooltip>
    <span v-else>{{ name }}</span>
  </span>
</template>

<script>
import mixins from 'vue-typed-mixins'

import { HIcon, HTooltip } from '@happytal/bo-ui-library'

export default {
  components: {
    HIcon,
    HTooltip
  },
  props: {
    name: {
      type: String,
      required: true
    }
  },
  computed: {
    isHappytal() {
      return this.name.toLowerCase().includes('happytal')
    }
  },
}
</script>
