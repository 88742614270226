



















import Vue from 'vue'

import { HCard } from '@happytal/bo-ui-library'

export default Vue.extend({
  components: {
    HCard,
  },
})
