<template>
  <LineCard>
    <template #bottom-left>
      <div class="d-flex align-center">
        <ServiceStatusChip
            :status="calendarItem.orderLine.state"
        />
        <div class="ml-2" v-if="calendarItem.application === 'happytalb2c'">
          <WebIcon />
        </div>
        <HBtn
          style-type="tertiary"
          icon="PackageIcon"
          size="small"
          color="normal"
          :to="getOrderRoute(calendarItem)"
          class="ml-2"
        >
          Voir la commande
        </HBtn>
      </div>
    </template>
    <template #bottom-right>
      <div class="d-flex align-center">
        <HBtn
          style-type="tertiary"
          icon="InfoCircleIcon"
          size="small"
          color="normal"
          @click="showInformation"
          class="ml-2"
        >
          Détails
        </HBtn>
        <slot name="action"></slot>
      </div>
    </template>

    <HRow :gap="2">
      <HCol :cols="12" :cols-md="4" class="d-flex align-center">
        <Thumbnail
          v-if="calendarItem.orderLine.imageUrl"
          :image-url="calendarItem.orderLine.imageUrl"
          size="small"
          cover
          class="mr-2"
        />
        <div>
          <div>
            {{ calendarItem.orderLine.title }}
          </div>
          <div class="text-s" v-html="serviceOptionsHtml"></div>
        </div>
      </HCol>
      <HCol :cols="4" :cols-md="3" class="d-flex align-center">
        <TitledInformation title="Bénéficiaire">
          <CustomerName
            :first-name="calendarItem.customer.firstName"
            :last-name="calendarItem.customer.lastName"
            :is-institution-staff="calendarItem.customer.isInstitutionStaff"
          />
        </TitledInformation>
      </HCol>
      <HCol :cols="4" :cols-md="3" class="d-flex align-center">
        <TitledInformation title="Prestataire">
          <ShopName :name="calendarItem.shop.name" />
        </TitledInformation>
      </HCol>
      <HCol :cols="4" :cols-md="2" class="d-flex align-center">
        <slot name="limit-date"></slot>
      </HCol>
    </HRow>
  </LineCard>
</template>

<script>
import _ from 'lodash'
import Vue, { PropType } from 'vue'

import Routes from '@/constants/routes'

import {
    getServiceOptionsAsHtml,
    getOrderServiceOptionsAsHtml
} from '@/modules/services'

import { HRow, HCol, HBtn } from '@happytal/bo-ui-library'

import ServiceStatusChip from '@/components/ServiceStatusChip.vue'
import LineCard from './LineCard.vue'
import Thumbnail from './Thumbnail.vue'
import TitledInformation from './TitledInformation.vue'
import CustomerName from './CustomerName.vue'
import ShopName from './ShopName.vue'
import WebIcon from './WebIcon.vue'

export default Vue.extend({
  components: {
    HRow,
    HCol,
    HBtn,
    ServiceStatusChip,
    LineCard,
    Thumbnail,
    TitledInformation,
    CustomerName,
    ShopName,
    WebIcon
  },
  props: {
    calendarItem: {
      type: Object,
      required: true
    }
  },
  computed: {
    serviceOptionsHtml () {
        const options = _.get(this.calendarItem, 'orderLine.options')
        return getOrderServiceOptionsAsHtml(options)
    }
  },
  methods: {
    getOrderRoute (calendarItem) {
      return {
          name: Routes.ResidentOrder,
          params: {
              resident_id: _.get(calendarItem, 'customer.id'),
              order_id: _.get(calendarItem, 'id')
          }
      }
    },
    showInformation () {
      this.$emit('show-information')
    }
  }
})
</script>
