import Vue from 'vue'
import { isAfter, isBefore, isEqual, parse } from 'date-fns'

export default Vue.extend({
  methods: {
    isDateWithinBounds(
      date, minDate, maxDate) {
      const candidate = parse(date, 'yyyy-MM-dd', new Date())
      if (
        minDate &&
        !(isAfter(candidate, minDate) || isEqual(candidate, minDate))
      ) {
        return false
      }
      if (
        maxDate &&
        !(isBefore(candidate, maxDate) || isEqual(candidate, maxDate))
      ) {
        return false
      }
      return true
    },
  },
})
