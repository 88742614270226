<template>
  <HSideBar :active="active" @update:active="hide">
    <template #footer>
      <div v-if="calendarItem" class="d-flex justify-end">
        <HBtn
          style-type="secondary"
          icon="PackageIcon"
          :to="{
            name: 'resident-order',
            params: {
                resident_id: calendarItem.customer.id,
                order_id: calendarItem.id
            }
          }"
        >
          Voir la commande
        </HBtn>
      </div>
    </template>

    <div v-if="calendarItem">
      <div class="service-image">
        <img
          :src="calendarItem.orderLine.imageUrl"
          :alt="calendarItem.orderLine.title"
        />
        <HBtn
          icon="XIcon"
          icon-only
          style-type="secondary"
          @click="hide"
          class="close-button"
          :style="{ 'background-color': 'white' }"
        />
      </div>

      <div class="my-4">
        <h2>{{ calendarItem.orderLine.title }}</h2>
        <p class="d-flex align-center">
          <span v-if="calendarItem.booking">
            <FormattedDate
              :value="calendarItem.booking.date"
              pattern="date-time"
            />
            <span class="mx-2">•</span>
          </span>
          <span>{{ calendarItem.duration }} min</span>
          <span v-if="movable && calendarItem.booking" class="ml-2">
            <DatePicker
              size="x-small"
              left
              :value="calendarItem.booking ? calendarItem.booking.date : null"
              @input="move"
            />
          </span>
        </p>
        <HAlert
          v-if="isAppointmentConsumptionLate(calendarItem)"
          dense
          text
          type="error"
          class="mt-4"
        >
          <span>
            Le service n'est pas marqué comme consommé par le prestataire.
          </span>
        </HAlert>
        <HAlert
          v-if="calendarItem.orderLine.state === 'ORDER_CANCELLED'"
          dense
          text
          type="info"
          class="mt-4"
        >
          <span> Le service a été annulé. </span>
        </HAlert>
        <p class="text-bold mt-2">Options</p>
        <LogisticServiceOrderLineOptions :order-line="calendarItem.orderLine" />
        <p class="text-bold mt-2">Créneau de prestation souhaité</p>
        <LogisticServiceOrderLineTimeSlots
          :order-line="calendarItem.orderLine"
        />
        <div v-if="calendarItem.orderLine.booking.additionalInfo">
          <p class="text-bold mt-2">Commentaire pour le prestataire</p>
          <p>{{ calendarItem.orderLine.booking.additionalInfo }}</p>
        </div>
      </div>

      <div class="infos-tabs">
        <HTabs>
          <HTab>
            <div class="d-flex align-center">
              <HIcon icon-name="UserIcon" size="16" class="mr-1" />
              <span>Bénéficiaire</span>
            </div>
          </HTab>
          <HTab>
            <div class="d-flex align-center">
              <HIcon icon-name="BuildingStoreIcon" size="16" class="mr-1" />
              <span>Partenaire</span>
            </div>
          </HTab>

          <HTabItem>
            <p class="text-bold text-lg mb-2">
              <CustomerName
                :is-institution-staff="calendarItem.customer.isInstitutionStaff"
                :last-name="calendarItem.customer.lastName"
                :first-name="calendarItem.customer.firstName"
              />
            </p>
            <FormattedCustomer :customer="calendarItem.customer" />
          </HTabItem>
          <HTabItem>
            <p class="text-bold text-lg mb-2">{{ calendarItem.shop.name }}</p>
            <FormattedShop :shop="calendarItem.shop" />
            <p class="text-bold my-2">Horaires d'ouverture</p>
            <p v-for="(openingHours, i) in calendarItem.openingHours" :key="i">
              <FormattedOpeningHours :value="openingHours" />
            </p>
          </HTabItem>
        </HTabs>
      </div>
    </div>
  </HSideBar>
</template>

<script>
import { PropType } from 'vue'
import mixins from 'vue-typed-mixins'
import { addMinutes, getHours, getMinutes } from 'date-fns'
import { setHours, setMinutes } from 'date-fns/fp'
import flow from 'lodash/fp/flow'

import {
  HBtn,
  HSideBar,
  HTabs,
  HTab,
  HTabItem,
  HIcon,
  HAlert,
} from '@happytal/bo-ui-library'

import CustomerName from './CustomerName.vue'
import FormattedShop from './FormattedShop.vue'
import FormattedCustomer from './FormattedCustomer.vue'
import FormattedDate from './FormattedDate.vue'
import FormattedOpeningHours from './FormattedOpeningHours.vue'
import DatePicker from './DatePicker.vue'
import LogisticServiceOrderLineOptions from './LogisticServiceOrderLineOptions.vue'
import LogisticServiceOrderLineTimeSlots from './LogisticServiceOrderLineTimeSlots.vue'

import AppointmentHelpers from '@/mixins/AppointmentHelpers'

export default mixins(AppointmentHelpers).extend({
  components: {
    HBtn,
    HSideBar,
    HTabs,
    HTab,
    HTabItem,
    HIcon,
    HAlert,
    CustomerName,
    FormattedShop,
    FormattedCustomer,
    FormattedDate,
    FormattedOpeningHours,
    DatePicker,
    LogisticServiceOrderLineOptions,
    LogisticServiceOrderLineTimeSlots,
  },
  props: {
    calendarItem: {
      type: Object,
      default: null,
    },
    active: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    movable() {
      return this.isAppointmentMovable(this.calendarItem)
    },
  },
  methods: {
    move(date) {
      const start = flow(
        setHours(getHours(this.calendarItem.booking.date)),
        setMinutes(getMinutes(this.calendarItem.booking.date)),
      )(date)
      this.$emit('move', {
        calendarItem: this.calendarItem,
        start,
        end: addMinutes(start, this.calendarItem.duration),
      })
    },
    hide() {
      this.$emit('update:active', false)
    },
  },
})
</script>

<style lang="scss" scoped>
.service-image {
  margin: 0 -2rem;

  img {
    height: 200px;
    width: 100%;
    object-fit: cover;
  }
}

.infos-tabs {
  margin: 0 -2rem;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 2rem;
}
</style>
