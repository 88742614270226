var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"thumbnail",class:{
    cover: _vm.cover,
    selected: _vm.selected,
    disabled: _vm.disabled,
    clickable: _vm.isClickable,
    small: _vm.size === 'small',
    large: _vm.size === 'large',
  },on:{"mouseover":_vm.mouseover,"mouseleave":_vm.mouseleave,"click":_vm.click}},[_c('img',{attrs:{"src":_vm.imageUrl,"srcset":_vm.imageSourceSet}})])}
var staticRenderFns = []

export { render, staticRenderFns }