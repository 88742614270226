<template>
  <span>
    <span v-if="isSameDay">
      Le <FormattedDate :value="value.from" /> de
      <FormattedDate :value="value.from" pattern="time" /> à
      <FormattedDate :value="value.to" pattern="time" />
    </span>
    <span v-else>
      Du <FormattedDate :value="value.from" pattern="date-time" /> au
      <FormattedDate :value="value.to" pattern="date-time" />
    </span>
  </span>
</template>

<script>
import Vue, { PropType } from 'vue'
import { isSameDay } from 'date-fns'

import FormattedDate from './FormattedDate.vue'

export default Vue.extend({
  components: {
    FormattedDate,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isSameDay() {
      return isSameDay(this.value.from, this.value.to)
    },
  },
})
</script>
