<template>
    <div class="logistic-appointments">
        <div class="title">{{ title }}</div>
        <div class="actions">
            <HBtn
                style-type="secondary"
                @click="goToToday"
                class="ml-2"
                data-cy="today-button"
            >Aujourd'hui</HBtn>
            <HBtn
                style-type="tertiary"
                icon="ChevronLeftIcon"
                icon-only
                @click="goBack"
                class="ml-2"
                data-cy="back-button"
            />
            <HBtn
                style-type="tertiary"
                icon="ChevronRightIcon"
                icon-only
                @click="goForward"
                class="ml-2"
                data-cy="forward-button"
            />
        </div>
        <div class="planning-view">
            <AcceptableCalendarItemsSection
                v-if="acceptableCalendarItems.length > 0"
                :calendar-items="acceptableCalendarItems"
                @show-information="showInformation"
                @accept="onAppointmentAccept"
                @refuse="onAppointmentRefuse"
            />
            <ConsumableCalendarItemsSection
                v-if="consumableCalendarItems.length > 0"
                :calendar-items="consumableCalendarItems"
                @show-information="showInformation"
                @consume="onAppointmentConsume"
            />
            <HPlanning
                class="planning"
                ref="planning"
                hide-calendar-header
                bar-list-title="Rendez-vous à programmer"
                :booking-proposals="bookingProposals"
                :appointments="appointments"
                v-model="planningModel"
                @validate-booking-proposal="validateBookingProposal"
                @move-appointment="moveAppointment"
                @move-non-movable-appointment="moveNonMovableAppointment"
                @click:event="selectAppointment"
                @click:booking-proposal="selectBookingProposal"
            >
                <template #bar-top>
                    <HFormField label="Partenaire">
                        <HSelect
                            v-model="selectedShops"
                            multiple
                            searchable
                            :options="shops"
                            track-by="id"
                            label="name"
                            :show-labels="false"
                            placeholder="Filtrer par partenaire"
                        />
                    </HFormField>
                </template>
                <template #booking-proposal="{ bookingProposal }">
                    <BookingProposalTile :calendar-item="bookingProposal.data" />
                </template>
                <template #event-appointment="{ event }">
                    <AppointmentCalendarEvent :calendar-item="event.appointment.data" />
                </template>
                <template #event-booking-proposal="{ event }">
                    <BookingProposalCalendarEvent
                        :calendar-item="event.bookingProposal.data"
                    />
                </template>
            </HPlanning>
        </div>
        <CalendarItemSideBar
            :active="sideBarActive"
            :calendar-item="selectedCalendarItem"
            @move="moveCalendarItem"
            @update:active="sideBarActive = false"
        />
        <ConfirmAcceptAppointmentPopin
            v-model="confirmAcceptAppointmentOpen"
            :calendar-item="calendarItem"
            @confirm="onAppointmentAcceptConfirm"
        />
        <ConfirmRefuseAppointmentPopin
            v-model="confirmRefuseAppointmentOpen"
            :calendar-item="calendarItem"
            @confirm="onAppointmentRefuseConfirm"
        />
        <ConfirmConsumeAppointmentPopin
            v-model="confirmConsumeAppointmentOpen"
            :calendar-item="calendarItem"
            @confirm="onAppointmentConsumeConfirm"
        />
    </div>
</template>

<script>
    import _ from 'lodash'
    import mixins from 'vue-typed-mixins'
    import moment from 'moment'

    import {
        addMinutes,
        endOfDay,
        endOfWeek,
        format,
        isAfter,
        isSameDay,
        isSameMonth,
        startOfDay,
        startOfWeek,
    } from 'date-fns'
    import { fr } from 'date-fns/locale'

    import Http from '@/http'

    import Formats from '@/constants/formats'
    import ServiceStatus from '@/constants/service-status'

    import {
        HBtn,
        HPlanning,
        HFormField,
        HSelect,
    } from '@happytal/bo-ui-library'

    import BookingProposalTile from './components/BookingProposalTile.vue'
    import AppointmentCalendarEvent from './components/AppointmentCalendarEvent.vue'
    import BookingProposalCalendarEvent from './components/BookingProposalCalendarEvent.vue'
    import CalendarItemSideBar from './components/CalendarItemSideBar.vue'
    import AcceptableCalendarItemsSection from './components/AcceptableCalendarItemsSection.vue'
    import ConsumableCalendarItemsSection from './components/ConsumableCalendarItemsSection.vue'
    import ConfirmAcceptAppointmentPopin from './components/ConfirmAcceptAppointmentPopin.vue'
    import ConfirmRefuseAppointmentPopin from './components/ConfirmRefuseAppointmentPopin.vue'
    import ConfirmConsumeAppointmentPopin from './components/ConfirmConsumeAppointmentPopin.vue'

    import AppointmentHelpers from '@/mixins/AppointmentHelpers'

    export default mixins(AppointmentHelpers).extend({
        name: 'LogisticAppointments',
        components: {
            HBtn,
            HPlanning,
            HFormField,
            HSelect,
            BookingProposalTile,
            AppointmentCalendarEvent,
            BookingProposalCalendarEvent,
            CalendarItemSideBar,
            AcceptableCalendarItemsSection,
            ConsumableCalendarItemsSection,
            ConfirmAcceptAppointmentPopin,
            ConfirmRefuseAppointmentPopin,
            ConfirmConsumeAppointmentPopin
        },
        data () {
            return {
                loaded: false,
                loadingAppointments: false,
                planningModel: undefined,
                viewType: 'week',
                selectedCalendarItemId: undefined,
                calendarItem: null,
                sideBarActive: false,
                confirmAcceptAppointmentOpen: false,
                confirmRefuseAppointmentOpen: false,
                confirmConsumeAppointmentOpen: false,
                selectedShops: []
            }
        },
        computed: {
            loading () {
                return !this.loaded
            },
            acceptableCalendarItems () {
                return _.get(this.$store, 'state.dataAppointments.acceptableCalendarItems')
            },
            consumableCalendarItems () {
                return _.get(this.$store, 'state.dataAppointments.consumableCalendarItems')
            },
            calendarItems () {
                return _.sortBy(this.$store.state.dataAppointments.calendarItems,
                    (calendarItem) => _.get(calendarItem, 'orderLine.booking.timeSlots[0].from', 0)
                )
            },
            filteredCalendarItems () {
                return this.calendarItems.filter((calendarItem) => {
                    if (this.selectedShops.length === 0) {
                        return true
                    }
                    return this.selectedShops.find(
                        (shop) => shop.id === calendarItem.shop.id
                    )
                })
            },
            bookingProposals () {
                return this.filteredCalendarItems.filter((calendarItem) => {
                    // Service already placed
                    if (calendarItem.booking) {
                        return false
                    }
                    // Service has been already consumed
                    if (calendarItem.consumption) {
                        return false
                    }
                    // Service status is cancelled
                    if (_.get(calendarItem, 'orderLine.state') == ServiceStatus.ORDER_CANCELLED) {
                        return false
                    }
                    return true
                })
                .map((calendarItem) => ({
                    id: calendarItem.orderLine.id,
                    openingDays: [],
                    duration: calendarItem.duration,
                    openingHours: _.map(calendarItem.openingHours, (openingHours) => ({
                        weekday: openingHours.weekday,
                        start: openingHours.from,
                        end: openingHours.to
                    })),
                    timeSlots: calendarItem.orderLine.booking.timeSlots.map(
                        (timeSlot) => ({
                            start: timeSlot.from,
                            end: timeSlot.to
                        })
                    ),
                    data: calendarItem
                }))
            },
            appointments () {
                if (this.loadingAppointments) {
                    return []
                }
                return this.filteredCalendarItems
                    .filter((calendarItem) => !!calendarItem.booking)
                    .map((calendarItem) => ({
                        id: calendarItem.orderLine.id,
                        start: calendarItem.booking.date,
                        end: addMinutes(calendarItem.booking.date, calendarItem.duration),
                        movable: this.isAppointmentMovable(calendarItem),
                        openingHours: _.map(calendarItem.openingHours, (openingHours) => ({
                            weekday: openingHours.weekday,
                            start: openingHours.from,
                            end: openingHours.to
                        })),
                        timeSlots: calendarItem.orderLine.booking.timeSlots.map(
                            (timeSlot) => ({
                                start: timeSlot.from,
                                end: timeSlot.to
                            })
                        ),
                        data: calendarItem
                    }))
                },
                shops () {
                    return _.uniqBy(
                        this.calendarItems.map((calendarItem) => calendarItem.shop),
                        (shop) => shop.id
                    )
                },
                title () {
                    if (this.viewType === 'month') {
                        const formattedPlanningModel = format(
                            this.planningModel ?? new Date(),
                            'MMMM yyyy',
                            {
                                locale: fr
                            }
                        )
                        return `Rendez-vous programmés en ${formattedPlanningModel}`
                    }
                    else if (this.viewType === 'week') {
                        const start = startOfWeek(this.planningModel ?? new Date(), {
                            weekStartsOn: 1
                        })
                        const end = endOfWeek(this.planningModel ?? new Date(), {
                            weekStartsOn: 1
                        })
                        if (isSameMonth(start, end)) {
                            const formattedStart = format(start, 'dd', {
                                locale: fr
                            })
                            const formattedEnd = format(end, 'dd MMMM yyyy', {
                                locale: fr
                            })
                            return `Rendez-vous programmés du ${formattedStart} au ${formattedEnd}`
                        }
                        else {
                            const formattedStart = format(start, 'dd MMMM', {
                                locale: fr
                            })
                            const formattedEnd = format(end, 'dd MMMM yyyy', {
                                locale: fr
                            })
                            return `Rendez-vous programmés du ${formattedStart} au ${formattedEnd}`
                        }
                    }
                    else {
                        return 'Rendez-vous programmés'
                    }
                },
                selectedCalendarItem () {
                    if (!this.selectedCalendarItemId) {
                        return undefined
                    }
                    return this.calendarItems.find(
                        (calendarItem) => calendarItem.id === this.selectedCalendarItemId,
                    )
                }
            },
            methods: {
                getUserId () {
                    return this.$store.getters['dataUser/getUserId']()
                },
                getInstitutionId () {
                    return this.$store.getters['dataUser/getInstitutionId']()
                },
                goToToday () {
                    this.planningModel = startOfWeek(new Date(), {
                        weekStartsOn: 1
                    })
                },
                goBack () {
                    const planning = this.$refs.planning
                    planning.goBack()
                },
                goForward () {
                    const planning = this.$refs.planning
                    planning.goForward()
                },
                validateBookingProposal (data) {
                    const {
                        startTime,
                        bookingProposal,
                        isOutOfOpeningHours
                    } = data
                    //console.log('startTime', startTime)
                    //console.log('bookingProposal', bookingProposal)
                    //console.log('isOutOfOpeningHours', isOutOfOpeningHours)
                    if (isAfter(startTime, new Date())) {
                        this.$store.dispatch('dataAppointments/validateBookingProposal', {
                            calendarItem: bookingProposal.data,
                            startTime,
                            userId: this.getUserId()
                        })
                        if (isSameDay(startTime, new Date())) {
                            this.showInfoToast(`Le prestataire ${bookingProposal.data.shop.name} ne sera pas prévenu automatiquement d'un tel changement. Merci de le contacter directement.`)
                        }
                        if (isOutOfOpeningHours) {
                            this.showInfoToast(`Le rendez-vous a été placé en dehors des créneaux souhaités / des heures ouvrées.`)
                        }
                    }
                    else {
                        this.showInfoToast(`Le rendez-vous ne peut être placé dans le passé`)
                    }
                },
                moveCalendarItem (data) {
                    const {
                        calendarItem,
                        start,
                        end,
                        isOutOfOpeningHours
                    } = data
                    if (isAfter(start, new Date())) {
                        this.$store.dispatch('dataAppointments/moveAppointment', {
                            calendarItem,
                            startTime: start,
                            endTime: end,
                            userId: this.getUserId()
                        })
                        if (isSameDay(start, new Date())) {
                            this.showInfoToast(`Le prestataire ${calendarItem.shop.name} ne sera pas prévenu automatiquement d'un tel changement. Merci de le contacter directement.`)
                        }
                        if (isOutOfOpeningHours) {
                            this.showInfoToast(`Le rendez-vous a été placé en dehors des créneaux souhaités / des heures ouvrées.`)
                        }
                    }
                    else {
                        this.showInfoToast(`Le rendez-vous ne peut être placé dans le passé`)
                    }
                },
                moveAppointment (data) {
                    const {
                        appointment,
                        start,
                        end,
                        isOutOfOpeningHours
                    } = data
                    this.moveCalendarItem({
                        calendarItem: appointment.data,
                        start,
                        end,
                        isOutOfOpeningHours
                    })
                },
                moveNonMovableAppointment (appointment) {
                    const appointmentData = _.get(appointment, 'appointment.data', {})
                    if (appointmentData.consumedAt) {
                        this.showInfoToast(`Le rendez-vous ne peut être déplacé car il a déjà été consommé`)
                    }
                    else if (_.get(appointmentData, 'orderLine.state') == 'ORDER_CANCELLED') {
                        this.showInfoToast(`Le rendez-vous ne peut être déplacé car il a été annulé`)
                    }
                },
                selectAppointment (appointment) {
                    this.selectedCalendarItemId = appointment.data.id
                    this.sideBarActive = true
                },
                selectBookingProposal (bookingProposal) {
                    this.selectedCalendarItemId = bookingProposal.data.id
                    this.sideBarActive = true
                },
                showInformation (calendarItem) {
                    this.selectedCalendarItemId = calendarItem.id
                    this.sideBarActive = true
                },
                onAppointmentAccept (calendarItem) {
                    this.calendarItem = calendarItem
                    this.confirmAcceptAppointmentOpen = true
                },
                onAppointmentAcceptConfirm () {
                    this.acceptAppointment()
                },
                acceptAppointment () {
                    this.$store.dispatch('dataAppointments/acceptAppointment', {
                        userId: this.getUserId(),
                        calendarItem: this.calendarItem
                    })
                },
                onAppointmentRefuse (calendarItem) {
                    this.calendarItem = calendarItem
                    this.confirmRefuseAppointmentOpen = true
                },
                onAppointmentRefuseConfirm () {
                    this.refuseAppointment()
                },
                refuseAppointment () {
                    this.$store.dispatch('dataAppointments/refuseAppointment', {
                        userId: this.getUserId(),
                        calendarItem: this.calendarItem
                    })
                },
                onAppointmentConsume (calendarItem) {
                    this.calendarItem = calendarItem
                    this.confirmConsumeAppointmentOpen = true
                },
                onAppointmentConsumeConfirm () {
                    this.consumeAppointment()
                },
                consumeAppointment () {
                    this.$store.dispatch('dataAppointments/consumeAppointment', {
                        userId: this.getUserId(),
                        calendarItem: this.calendarItem
                    })
                },
                showInfoToast (text) {
                    this.$toasted.show(text, {
                        theme: 'hx-toasted',
                        position: 'hx-bottom-left',
                        duration: 5000,
                        action: [
                            {
                                text: 'Fermer',
                                onClick: (e, to) => {
                                    to.goAway(0)
                                }
                            }
                        ]
                    })
                }
            },
            async mounted () {
                await this.$store.dispatch('dataAppointments/fetchAcceptableCalendarItems', {
                    userId: this.getUserId(),
                    institutionId: this.getInstitutionId()
                })
                await this.$store.dispatch('dataAppointments/fetchConsumableCalendarItems', {
                    userId: this.getUserId(),
                    institutionId: this.getInstitutionId()
                })
            },
            watch: {
                async planningModel (value) {
                    switch (this.viewType) {
                        case 'week':
                            this.loadingAppointments = true
                            await this.$store.dispatch('dataAppointments/fetchCalendarItems', {
                                userId: this.getUserId(),
                                institutionId: this.getInstitutionId(),
                                bookingDateFrom: format(startOfDay(
                                    startOfWeek(value, {
                                        weekStartsOn: 1
                                    })), `yyyy-MM-dd`),
                                bookingDateTo: format(endOfDay(
                                    endOfWeek(value, {
                                        weekStartsOn: 1
                                    })), `yyyy-MM-dd`)
                            })
                            this.loadingAppointments = false
                            break
                        }
                    }
                }
    })
</script>

<style lang="scss" scoped>
.logistic-appointments-view {
    padding: 30px 0px 30px 0px;
}

.logistic-appointments {

    .title {
        padding: 0px 0px 20px 0px;
        color: black;
    }
    .actions {
        margin: 0px 0px 20px 0px;
    }
    .planning-view {

        .planning {

        }
    }
}
</style>
