<template>
  <div
    class="event pl-1"
    :class="{
      'late-consumption': isAppointmentConsumptionLate(calendarItem),
      cancelled: calendarItem.orderLine.state === 'ORDER_CANCELLED',
    }"
    data-cy="appointment-event"
  >
    <div class="d-flex align-center relative">
      <span class="text-bold mr-1">{{ calendarItem.duration }} min</span>
      <span>{{ calendarItem.orderLine.title }}</span>
      <HIcon
        v-if="isAppointmentConsumptionLate(calendarItem)"
        icon-name="AlertCircleIcon"
        class="alert-icon"
      />
    </div>
  </div>
</template>

<script>
import { PropType } from 'vue'
import mixins from 'vue-typed-mixins'

import { HIcon } from '@happytal/bo-ui-library'

import AppointmentHelpers from '@/mixins/AppointmentHelpers'

export default mixins(AppointmentHelpers).extend({
  components: {
    HIcon,
  },
  props: {
    calendarItem: {
      type: Object,
      required: true,
    },
  },
})
</script>

<style lang="scss" scoped>
.event {
  user-select: none;
}

.late-consumption {
  background-color: white;
  border: 1px solid var(--v-error-base);
  border-radius: 4px;
  height: 100%;
  color: var(--v-error-base);
}

.cancelled {
  background-color: white;
  border: 1px solid var(--v-primary-base);
  border-radius: 4px;
  height: 100%;
  color: var(--v-primary-base);
  text-decoration: line-through;
}

.alert-icon {
  position: absolute;
  right: 0;
  padding: 1px 4px 0 2px;
  background-color: white;
}
</style>
