<template>
  <div>
    <div>
      {{ shop.contactInformation.firstName }}
      {{ shop.contactInformation.lastName }}
    </div>
    <FormattedEmail
      v-if="shop.contactInformation.email"
      :value="shop.contactInformation.email"
      class="mt-1"
      data-cy="shop-email"
    />
    <FormattedPhone
      v-if="shop.contactInformation.phone"
      :value="shop.contactInformation.phone"
      class="mt-1"
      data-cy="shop-phone"
    />
    <FormattedUrl
      v-if="shop.contactInformation.webSite"
      :value="shop.contactInformation.webSite"
      class="mt-1"
      data-cy="shop-website"
    />
    <FormattedAddress
      v-if="
        shop.contactInformation.street1 ||
        shop.contactInformation.street2 ||
        shop.contactInformation.zipCode ||
        shop.contactInformation.city
      "
      :street1="shop.contactInformation.street1"
      :street2="shop.contactInformation.street2"
      :zip-code="shop.contactInformation.zipCode"
      :city="shop.contactInformation.city"
      class="mt-1"
      data-cy="shop-address"
    />
  </div>
</template>

<script>
import Vue from 'vue'

import FormattedEmail from './FormattedEmail.vue'
import FormattedPhone from './FormattedPhone.vue'
import FormattedUrl from './FormattedUrl.vue'
import FormattedAddress from './FormattedAddress.vue'

export default Vue.extend({
  components: {
    FormattedEmail,
    FormattedPhone,
    FormattedUrl,
    FormattedAddress,
  },
  props: {
    shop: {
      type: Object,
      required: true,
    },
  },
})
</script>
