<template>
    <ConfirmPopin
        :value="value"
        v-on="$listeners"
        class="confirm-consume-appointment-popin"
        title="Consommer le rendez-vous ?"
    >
        Consommer le rendez-vous <strong>{{ serviceName }}</strong>
        de la part de <strong>{{ shopName }}</strong> ?
        <br />
        <br />
        La prestation doit avoir été réalisée.
    </ConfirmPopin>
</template>

<script>
    import ConfirmPopin from '@/components/popins/ConfirmPopin.vue'

    export default {
        name: 'ConfirmConsumeAppointmentPopin',
        model: {
            prop: 'value',
            event: 'input'
        },
        components: {
            ConfirmPopin
        },
        props: {
            value: {
                type: Boolean,
                default: false
            },
            calendarItem: {
                type: Object
            }
        },
        computed: {
            shopName () {
                return _.get(this.calendarItem, 'shop.name')
            },
            serviceName () {
                return _.get(this.calendarItem, 'orderLine.title')
            }
        }
    }
</script>

<style lang="scss" scoped>
    .confirm-consume-appointment-popin::v-deep {

        .h-popin__dialog {
            min-height: 230px;
        }
    }
</style>
