<template>
  <div class="d-flex align-start">
    <HIcon icon-name="BuildingStoreIcon" size="16" class="mr-1 mt-1" />
    <span>
      <span v-if="street1">{{ street1 }}<br /></span>
      <span v-if="street2">{{ street2 }}<br /></span>
      <span v-if="zipCode || city">{{ zipCode }} {{ city }}</span>
    </span>
  </div>
</template>

<script>
import mixins from 'vue-typed-mixins'

import { HIcon } from '@happytal/bo-ui-library'

import Formatters from '@/mixins/Formatters'

export default mixins(Formatters).extend({
  components: {
    HIcon,
  },
  props: {
    street1: {
      type: String,
      default: null,
    },
    street2: {
      type: String,
      default: null,
    },
    zipCode: {
      type: String,
      default: null,
    },
    city: {
      type: String,
      default: null,
    },
  },
})
</script>
