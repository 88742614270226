<template>
  <div>
    <p v-for="(timeSlot, i) in orderLine.booking.timeSlots" :key="i">
      <FormattedTimeSlot :value="timeSlot" />
    </p>
  </div>
</template>

<script>
import Vue, { PropType } from 'vue'

import FormattedTimeSlot from './FormattedTimeSlot.vue'

export default Vue.extend({
  components: {
    FormattedTimeSlot,
  },
  props: {
    orderLine: {
      type: Object,
      required: true,
    },
  },
  computed: {
    options() {
      const options = []
      for (const option of this.orderLine.options) {
        switch (option.type) {
          case 'BOOLEAN':
            if (option.value) {
              options.push(option.label)
            }
            break
          case 'VALUE_LIST':
            for (const value of option.values) {
              options.push(value.label)
            }
            break
        }
      }
      return options
    },
  },
})
</script>
