import Vue from 'vue'
import { differenceInBusinessDays } from 'date-fns'

export default Vue.extend({
  methods: {
    isAppointmentMovable(calendarItem) {
      return (
        !calendarItem.consumedAt &&
        calendarItem.orderLine.state !== 'ORDER_CANCELLED'
      )
    },
    isAppointmentConsumptionLate(calendarItem) {
      if (!calendarItem.booking) {
        return false
      }
      return (
        calendarItem.orderLine.state === 'ORDER_PENDING' &&
        !calendarItem.consumedAt &&
        differenceInBusinessDays(new Date(), calendarItem.booking.date) > 2
      )
    },
  },
})
