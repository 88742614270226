



















import mixins from 'vue-typed-mixins'

import ImageHelpers from '@/mixins/ImageHelpers'

export default mixins(ImageHelpers).extend({
  props: {
    imageUrl: {
      type: String,
      required: true,
    },
    cover: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clickable: {
      type: Boolean,
      default: null,
    },
    size: {
      type: String,
      default: 'medium',
    },
  },
  computed: {
    isClickable(): boolean {
      const clickable = this.clickable ?? !this.disabled
      return !!this.$listeners.click && clickable
    },
    imageSourceSet(): string {
      switch (this.size) {
        case 'small':
          return this.buildSourceSet(this.imageUrl, {
            width: 40,
            height: 40,
            fit: this.cover ? 'cover' : 'contain',
          })
        case 'medium':
          return this.buildSourceSet(this.imageUrl, {
            width: 80,
            height: 80,
            fit: this.cover ? 'cover' : 'contain',
          })
        default:
          return this.imageUrl
      }
    },
  },
  methods: {
    mouseover() {
      if (this.isClickable) {
        this.$emit('mouseover')
      }
    },
    mouseleave() {
      if (this.isClickable) {
        this.$emit('mouseleave')
      }
    },
    click() {
      if (this.isClickable) {
        this.$emit('click')
      }
    },
  },
})
