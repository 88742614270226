import Vue from 'vue'

interface ResizeOptions {
  width?: number
  height?: number
  fit?: 'contain' | 'cover'
}

export default Vue.extend({
  methods: {
    buildResizedUrl(url: string, options: ResizeOptions, density = 1) {
      // URL format docs: https://developers.cloudflare.com/images/image-resizing/url-format/
      const baseOptions = [
        `format=auto`, // Use avif or webp if supported by the browser
        `fit=${options.fit ?? 'contain'}`, // Scale up or down if needed
        `dpr=${density}`, // Device pixel ratio for high density images
      ]
      const optionsString =
        baseOptions.join(',') +
        ',' +
        Object.entries(options)
          .map(([key, value]) => `${key}=${value}`)
          .join(',')
      return `https://happytal.com/cdn-cgi/image/${optionsString}/${url}`
    },
    buildSourceSet(url: string, options: ResizeOptions) {
      const densities = [1, 2, 3]
      return densities
        .map(
          (density) =>
            `${this.buildResizedUrl(url, options, density)} ${density}x`,
        )
        .join(',')
    },
  },
})
