




import Vue, { PropType } from 'vue'
import { differenceInCalendarDays } from 'date-fns'

export default Vue.extend({
  props: {
    value: {
      type: Date as PropType<Date>,
      required: true,
    },
  },
  computed: {
    formattedValue(): string {
      const diff = differenceInCalendarDays(new Date(), this.value)
      if (diff === 0) {
        return "aujourd'hui"
      } else if (diff === -1) {
        return 'demain'
      } else if (diff === 1) {
        return 'hier'
      } else if (diff < 0) {
        return `dans ${-diff} jours`
      } else {
        return `il y a ${diff} jours`
      }
    },
  },
})
