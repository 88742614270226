<template>
    <HChip
        class="service-status-chip"
        :color="statusColor"
        :textColor="statusTextColor"
    >
        {{ statusText }}
    </HChip>
</template>

<script>
    import _ from 'lodash'

    import {
        getServiceStatusLabel
    } from '@/modules/labels'

    import ServiceStatus from '@/constants/service-status'

    import { HChip } from '@happytal/bo-ui-library'

    export default {
        name: 'ServiceStatusChip',
        components: {
            HChip
        },
        props: {
            status: {
                type: String
            }
        },
        computed: {
            statusText () {
                return getServiceStatusLabel(this.status)
            },
            statusColor () {
                switch (this.status) {
                    case ServiceStatus.WAITING_SCORING:
                    case ServiceStatus.SCORING_OK:
                    case ServiceStatus.WAITING_ACCEPTANCE:
                    case ServiceStatus.ORDER_ACCEPTED:
                    case ServiceStatus.ORDER_PENDING:
                        return ''
                    case ServiceStatus.ORDER_REFUSED:
                    case ServiceStatus.ORDER_EXPIRED:
                    case ServiceStatus.ORDER_CANCELLED:
                        return 'orange'
                    case ServiceStatus.SCORING_KO:
                        return 'red'
                    case ServiceStatus.ORDER_CONSUMED:
                    case ServiceStatus.ORDER_CLOSED:
                        return 'green'
                }
            },
            statusTextColor () {
                switch (this.status) {
                    case ServiceStatus.WAITING_SCORING:
                    case ServiceStatus.SCORING_OK:
                    case ServiceStatus.WAITING_ACCEPTANCE:
                    case ServiceStatus.ORDER_ACCEPTED:
                    case ServiceStatus.ORDER_PENDING:
                        return ''
                    case ServiceStatus.ORDER_REFUSED:
                    case ServiceStatus.ORDER_EXPIRED:
                    case ServiceStatus.ORDER_CANCELLED:
                        return 'white'
                    case ServiceStatus.SCORING_KO:
                        return 'white'
                    case ServiceStatus.ORDER_CONSUMED:
                    case ServiceStatus.ORDER_CLOSED:
                        return 'white'
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
@import '~@happytal/bo-ui-library/src/styles/variables';

.order-status-chip {

}
</style>
