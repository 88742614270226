<template>
  <div class="event px-1">
    <span class="text-bold">{{ calendarItem.duration }} min</span>
    {{ calendarItem.orderLine.title }}
  </div>
</template>

<script>
import Vue, { PropType } from 'vue'

export default Vue.extend({
  props: {
    calendarItem: {
      type: Object,
      required: true,
    },
  },
})
</script>

<style lang="scss" scoped>
.event {
  background-color: white;
  border: 1px solid black;
  border-radius: 4px;
  height: 100%;
  color: black;
}
</style>
