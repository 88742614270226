<template>
    <HPopin
        :value="value"
        @input="close"
        width="small"
        class="confirm-popin"
    >
        <template #header>
            {{ title }}
        </template>
        <slot />
        <template #actions>
            <HBtn
                v-for="(action, actionIndex) in actions"
                :key="actionIndex"
                @click="(e) => onActionBtnClick(e, action.name)"
                :style-type="action.type"
                :data-cy="`${action.name}-btn`"
            >{{ action.label }}</HBtn>
        </template>
    </HPopin>
</template>

<script>
    import {
        HPopin,
        HBtn
    } from '@happytal/bo-ui-library'

    export default {
        name: 'ConfirmPopin',
        model: {
            prop: 'value',
            event: 'input'
        },
        components: {
            HPopin,
            HBtn
        },
        props: {
            value: {
                type: Boolean,
                default: false
            },
            title: {
                type: String
            },
            actions: {
                type: Array,
                default: () => [
                    {
                        name: 'cancel',
                        type: 'tertiary',
                        label: 'Annuler'
                    },
                    {
                        name: 'confirm',
                        type: 'primary',
                        label: 'Confirmer'
                    }
                ]
            }
        },
        methods: {
            close () {
                this.$emit('input', false)
            },
            onActionBtnClick (e, name) {
                this.close()
                this.$emit(name)
            }
        }
    }
</script>
