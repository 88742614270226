import Vue from 'vue'

import {
  sanitizeEmail,
  sanitizeFirstName,
  sanitizeLastName,
  sanitizePhone,
} from '@/modules/sanitizers'

export default Vue.extend({
  methods: {
    formatNumber(value) {
      const number = Number(value.toFixed(2))
      return number.toLocaleString('fr-FR')
    },
    formatPrice(value) {
      const number = Number(value.toFixed(2))
      if (Number.isInteger(number)) {
        return number.toLocaleString('fr-FR')
      } else {
        return number.toLocaleString('fr-FR', {
          minimumFractionDigits: 2,
        })
      }
    },
    formatFirstName(value) {
      return sanitizeFirstName(value)
    },
    formatLastName(value) {
      return sanitizeLastName(value)
    },
    formatEmail(value) {
      return sanitizeEmail(value)
    },
    formatPhone(value) {
      return sanitizePhone(value)
    },
    formatUrl(value) {
      return value.trim().replace(/https?:\/\//, '')
    },
  },
})
