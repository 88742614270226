<template>
  <div class="consumable-section">
    <div class="section-title">Rendez-vous à consommer</div>
    <div class="section-content">
      <CalendarItemLineCard
        class="calendar-item"
        v-for="calendarItem in calendarItems"
        :key="calendarItem.id"
        :calendar-item="calendarItem"
        @show-information="showInformation(calendarItem)"
        data-cy="consumable-calendar-item"
      >
        <template #limit-date>
          <TitledInformation title="Expiration">
            <FormattedDuration :value="getExpirationDate(calendarItem)" />
          </TitledInformation>
        </template>
        <template #action>
          <ConditionalTooltip
            :enabled="calendarItem.orderLine.state === 'ORDER_CONSUMED'"
            color="neutral"
          >
            <template #activator>
              <HBtn
                style-type="primary"
                icon="CheckIcon"
                size="small"
                :disabled="calendarItem.orderLine.state === 'ORDER_CONSUMED'"
                @click="consume(calendarItem)"
                class="ml-2"
                data-cy="consume-button"
              >
                Marquer consommé
              </HBtn>
            </template>
            <template #tooltip>Le rendez-vous est consommé</template>
          </ConditionalTooltip>
        </template>
      </CalendarItemLineCard>
    </div>
  </div>
</template>

<script>
import Vue, { PropType } from 'vue'

import { HBtn } from '@happytal/bo-ui-library'

import TitledInformation from './TitledInformation.vue'
import FormattedDuration from './FormattedDuration.vue'
import ConditionalTooltip from '@/components/ConditionalTooltip.vue'
import CalendarItemLineCard from './CalendarItemLineCard.vue'

export default Vue.extend({
  components: {
    HBtn,
    TitledInformation,
    CalendarItemLineCard,
    FormattedDuration,
    ConditionalTooltip,
  },
  props: {
    calendarItems: {
      type: Array,
      required: true
    }
  },
  methods: {
    getExpirationDate (calendarItem) {
      return calendarItem.expirationDate
    },
    showInformation (calendarItem) {
      this.$emit('show-information', calendarItem)
    },
    consume (calendarItem) {
      this.$emit('consume', calendarItem)
    }
  }
})
</script>

<style lang="scss" scoped>
.consumable-section {

    .section-title {
        padding: 0px 0px 20px 0px;
        font-size: 15px;
        font-weight: 600;
        color: black;
    }
    .section-content {

        .calendar-item {
            margin: 0px 0px 20px 0px;
        }
    }
}
</style>
