<template>
  <div class="acceptable-section">
      <div class="section-title">Rendez-vous à accepter</div>
      <div class="section-content">
      <CalendarItemLineCard
        class="calendar-item"
        v-for="calendarItem in calendarItems"
        :key="calendarItem.id"
        :calendar-item="calendarItem"
        @show-information="showInformation(calendarItem)"
        data-cy="acceptable-calendar-item"
      >
        <template #limit-date>
          <TitledInformation title="Expiration">
            <FormattedDuration :value="getExpirationDate(calendarItem)" />
          </TitledInformation>
        </template>
        <template #action>
          <ConditionalTooltip
            :enabled="calendarItem.orderLine.state == 'ORDER_ACCEPTED'"
            color="neutral"
          >
            <template #activator>
              <HBtn
                style-type="primary"
                icon="CheckIcon"
                size="small"
                :disabled="calendarItem.orderLine.state !== 'WAITING_ACCEPTANCE'"
                @click="accept(calendarItem)"
                class="ml-2"
                data-cy="accept-button"
              >
                Accepter
              </HBtn>
            </template>
            <template #tooltip>Le rendez-vous est accepté</template>
          </ConditionalTooltip>
          <ConditionalTooltip
            :enabled="calendarItem.orderLine.state == 'ORDER_CANCELLED'"
            color="neutral"
          >
            <template #activator>
              <HBtn
                style-type="secondary"
                icon="CheckIcon"
                size="small"
                :disabled="calendarItem.orderLine.state !== 'WAITING_ACCEPTANCE'"
                @click="refuse(calendarItem)"
                class="ml-2"
                data-cy="refuse-button"
              >
                Refuser
              </HBtn>
            </template>
            <template #tooltip>Le rendez-vous est refusé</template>
          </ConditionalTooltip>
        </template>
      </CalendarItemLineCard>
  </div>
  </div>
</template>

<script>
import _ from 'lodash'
import Vue, { PropType } from 'vue'
import { addDays } from 'date-fns'

import { HBtn } from '@happytal/bo-ui-library'

import TitledInformation from './TitledInformation.vue'
import FormattedDuration from './FormattedDuration.vue'
import ConditionalTooltip from '@/components/ConditionalTooltip.vue'
import CalendarItemLineCard from './CalendarItemLineCard.vue'

import ServiceRules from '@/constants/service-rules'

export default Vue.extend({
  components: {
    HBtn,
    TitledInformation,
    CalendarItemLineCard,
    FormattedDuration,
    ConditionalTooltip,
  },
  props: {
    calendarItems: {
      type: Array,
      required: true
    },
  },
  methods: {
    getExpirationDate (calendarItem) {
      return addDays(new Date(calendarItem.createdAt), ServiceRules.ORDER_ACCEPTATION_MAX_DAYS)
    },
    showInformation (calendarItem) {
      this.$emit('show-information', calendarItem)
    },
    accept (calendarItem) {
      this.$emit('accept', calendarItem)
    },
    refuse (calendarItem) {
      this.$emit('refuse', calendarItem)
    }
  }
})
</script>

<style lang="scss" scoped>
.acceptable-section {

    .section-title {
        padding: 0px 0px 20px 0px;
        font-size: 15px;
        font-weight: 600;
        color: black;
    }
    .section-content {

        .calendar-item {
            margin: 0px 0px 20px 0px;
        }
    }
}
</style>
