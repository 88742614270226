<template>
  <HMenu hide-arrow :left="left" min-width="auto" max-height="auto">
    <template #activator>
      <HBtn
        style-type="tertiary"
        :size="size"
        :disabled="disabled"
        icon="CalendarIcon"
        icon-only
      />
    </template>
    <template #items>
      <HDatePicker
        no-title
        :value="formattedValue"
        :allowed-dates="isDateAllowed"
        @input="selectDate"
      />
    </template>
  </HMenu>
</template>

<script>
import { PropType } from 'vue'
import mixins from 'vue-typed-mixins'
import { parse, format } from 'date-fns'

import { HBtn, HMenu, HDatePicker } from '@happytal/bo-ui-library'

import DateHelpers from '@/mixins/DateHelpers'

export default mixins(DateHelpers).extend({
  components: {
    HMenu,
    HBtn,
    HDatePicker,
  },
  props: {
    value: {
      type: Date,
      default: null,
    },
    minDate: {
      type: Date,
      default: null,
    },
    maxDate: {
      type: Date,
      default: null,
    },
    left: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    formattedValue() {
      return this.value ? format(this.value, 'yyyy-MM-dd') : ''
    },
  },
  methods: {
    isDateAllowed(date) {
      return this.isDateWithinBounds(date, this.minDate, this.maxDate)
    },
    selectDate(date) {
      this.$emit('input', parse(date, 'yyyy-MM-dd', new Date()))
    },
  },
})
</script>
