<template>
  <div>
    <FormattedEmail
      v-if="customer.email"
      :value="customer.email"
      class="mt-1"
      data-cy="customer-email"
    />
    <FormattedPhone
      v-if="customer.phone"
      :value="customer.phone"
      class="mt-1"
      data-cy="customer-phone"
    />
  </div>
</template>

<script>
import Vue, { PropType } from 'vue'

import FormattedEmail from './FormattedEmail.vue'
import FormattedPhone from './FormattedPhone.vue'

export default Vue.extend({
  components: {
    FormattedEmail,
    FormattedPhone,
  },
  props: {
    customer: {
      type: Object,
      required: true,
    },
  },
})
</script>
