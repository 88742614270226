

















import Vue from 'vue'

import { HIcon, HTooltip } from '@happytal/bo-ui-library'

export default Vue.extend({
  components: {
    HIcon,
    HTooltip,
  },
  props: {
    top: {
      type: Boolean,
      default: false,
    },
    bottom: {
      type: Boolean,
      default: false,
    },
    left: {
      type: Boolean,
      default: false,
    },
    right: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'primary--text',
    },
    size: {
      type: String,
      default: '25',
    },
  },
})
