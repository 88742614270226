<template>
  <div>
    <ul v-if="options.length > 0">
      <li v-for="option in options" :key="option">{{ option }}</li>
    </ul>
  </div>
</template>

<script>
import Vue, { PropType } from 'vue'

export default Vue.extend({
  props: {
    orderLine: {
      type: Object,
      required: true,
    },
  },
  computed: {
    options() {
      const options = []
      for (const option of this.orderLine.options) {
        switch (option.type) {
          case 'BOOLEAN':
            if (option.value) {
              options.push(option.label)
            }
            break
          case 'VALUE_LIST':
            for (const value of option.values) {
              options.push(value.label)
            }
            break
        }
      }
      return options
    },
  },
})
</script>
