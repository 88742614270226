<template>
  <span class="d-flex align-center">
    <span>{{ firstName }} {{ lastName }}</span>
    <HTooltip color="neutral">
      <template #activator="{ on, attrs }">
        <span class="d-flex" v-bind="attrs" v-on="on">
          <HIcon
            v-if="isInstitutionStaff"
            icon-name="StethoscopeIcon"
            class="ml-2"
          />
        </span>
      </template>
      <span>Personnel hospitalier</span>
    </HTooltip>
  </span>
</template>

<script>
import Vue from 'vue'

import { HIcon, HTooltip } from '@happytal/bo-ui-library'

export default Vue.extend({
  components: {
    HIcon,
    HTooltip,
  },
  props: {
    firstName: {
      type: String,
      required: true,
    },
    lastName: {
      type: String,
      required: true,
    },
    isInstitutionStaff: {
      type: Boolean,
      required: true,
    },
  },
})
</script>
