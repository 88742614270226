<template>
  <span>Le {{ day }} de {{ value.from }} à {{ value.to }}</span>
</template>

<script>
import Vue, { PropType } from 'vue'

export default Vue.extend({
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  computed: {
    day() {
      switch (this.value.weekday) {
        case 0:
          return 'dimanche'
        case 1:
          return 'lundi'
        case 2:
          return 'mardi'
        case 3:
          return 'mercredi'
        case 4:
          return 'jeudi'
        case 5:
          return 'vendredi'
        case 6:
          return 'samedi'
        default:
          return ''
      }
    },
  },
})
</script>
