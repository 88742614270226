import { capitalize, identity, trim, upperFirst } from 'lodash'
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber'

const phoneUtil = PhoneNumberUtil.getInstance()

export function sanitizeFirstName(value: string): string {
  try {
    return trim(value)
      .toLowerCase()
      .replace(/francois/g, 'françois')
      .split(' ')
      .filter(identity)
      .map(capitalize)
      .join('-')
      .split('-')
      .map(upperFirst)
      .join('-')
      .replace(/\s*-\s*/g, '-')
  } catch (err) {
    console.error('Error while sanitizing first name', value, err)
    return value
  }
}

export function sanitizeLastName(value: string): string {
  try {
    return trim(value)
      .toUpperCase()
      .split(' ')
      .filter(identity)
      .join(' ')
      .replace(/\s*-\s*/g, '-')
  } catch (err) {
    console.error('Error while sanitizing last name', value, err)
    return value
  }
}

export function sanitizeEmail(value: string): string {
  try {
    return trim(value).toLowerCase()
  } catch (err) {
    console.error('Error while sanitizing email', value, err)
    return value
  }
}

export function sanitizePhone(value: string): string {
  if (!value) {
    return value
  }
  try {
    const phoneNumber = phoneUtil.parse(value, 'FR')
    if (!phoneUtil.isValidNumber(phoneNumber)) {
      return value
    }
    return phoneUtil.format(phoneNumber, PhoneNumberFormat.NATIONAL)
  } catch (err) {
    console.error('Error while sanitizing phone number', value, err)
    return value
  }
}
